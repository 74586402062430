import {
  Accordion,
  Card,
  Col,
  Container,
  Nav,
  Row,
  Tab,
  Table,
} from "react-bootstrap"
import { Link, withI18next } from "gatsby-plugin-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { I18n } from "react-i18next"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import { faSeedling } from "@fortawesome/free-solid-svg-icons"
import { graphql } from "gatsby"
import timeline_en from "../images/plan/timeline_en.png"
import timeline_mn from "../images/plan/timeline_mn.png"

function Plan({ data: { contentfulStats }, lng }) {
  return (
    <I18n>
      {t => (
        <Layout>
          <SEO title={t("strategy")} />
          <div className="section bg-dark-blue mt-4">
            <Container className="height-md-30">
              <Row>
                <Col md="8" lg="7" xl="6">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0 bg-dark-blue bg-transparent">
                      <li className="breadcrumb-item">
                        <Link to="/" className="text-white">
                          {t("home")}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/plan" className="text-white">
                          {t("strategy")}
                        </Link>
                      </li>
                    </ol>
                  </nav>
                </Col>
                <Col md="12">
                  <h1 className="display-5 text-white">
                    {t("strategy")} <FontAwesomeIcon icon={faSeedling} />
                  </h1>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="content">
            <Container>
              <Row>
                <Col>
                  <h2>{t("strategy_desc")}</h2>
                  <Tab.Container defaultActiveKey="one_voice">
                    <div className="tab-container">
                      <Nav fill className="tab-nav">
                        <Nav.Item>
                          <Nav.Link eventKey="one_voice">
                            {t("one_voice_title")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="one_roof">
                            {t("one_roof_title")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="one_image">
                            {t("one_image_title")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="one_voice">
                          <h3>{t("one_voice")}</h3>
                          <p>{t("one_voice_desc")}</p>
                          <Accordion defaultActiveKey="1">
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                {t("one_voice_1")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>{t("one_voice_1_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                {t("one_voice_2")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>{t("one_voice_2_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3">
                                {t("one_voice_3")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="3">
                                <Card.Body>{t("one_voice_3_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="4">
                                {t("one_voice_4")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="4">
                                <Card.Body>{t("one_voice_4_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="5">
                                {t("one_voice_5")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="5">
                                <Card.Body>{t("one_voice_5_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </Tab.Pane>
                        <Tab.Pane eventKey="one_roof">
                          <h3>{t("one_roof")}</h3>
                          <p>{t("one_roof_desc")}</p>
                          <Accordion defaultActiveKey="1">
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                {t("one_roof_1")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>{t("one_roof_1_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                {t("one_roof_2")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>{t("one_roof_2_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3">
                                {t("one_roof_3")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="3">
                                <Card.Body>{t("one_roof_3_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </Tab.Pane>
                        <Tab.Pane eventKey="one_image">
                          <h3>{t("one_image")}</h3>
                          <p>{t("one_image_desc")}</p>
                          <Accordion defaultActiveKey="1">
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                {t("one_image_1")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>{t("one_image_1_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                {t("one_image_2")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>{t("one_image_2_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3">
                                {t("one_image_3")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="3">
                                <Card.Body>{t("one_image_3_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="4">
                                {t("one_image_4")}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="4">
                                <Card.Body>{t("one_image_4_desc")}</Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </Col>
              </Row>
            </Container>
            <div className="section">
              <Container>
                <Row>
                  <Col>
                    {lng === "en" ? (
                      <img src={timeline_en} alt="Timeline" />
                    ) : (
                      <img src={timeline_mn} alt="Timeline" />
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section">
              <Container>
                <Row>
                  <Col>
                    <h2 className="mb-5">{t("why_change")}</h2>
                    <h4 className="mb-3 font-weight-bold">{t("harmony")}</h4>
                    <ul>
                      <li>
                        <h5>{t("harmony_1")}</h5>
                        <ul>
                          <li>
                            <p>{t("harmony_1_1")}</p>
                          </li>
                          <li>
                            <p>{t("harmony_1_2")}</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h5>{t("harmony_2")}</h5>
                        <ul>
                          <li>
                            <p>{t("harmony_2_1")}</p>
                          </li>
                          <li>
                            <p>{t("harmony_2_2")}</p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <h4 className="mb-3 font-weight-bold">{t("principles")}</h4>
                    <ul>
                      <li>
                        <h5>{t("branding")}</h5>
                        <ul>
                          <li>
                            <p>{t("branding_1")}</p>
                          </li>
                          <li>
                            <p>{t("branding_2")}</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h5>{t("funding")}</h5>
                        <ul>
                          <li>
                            <p>{t("funding_1")}</p>
                          </li>
                          <li>
                            <p>{t("funding_2")}</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h5>{t("supporting")}</h5>
                        <ul>
                          <li>
                            <p>{t("supporting_1")}</p>
                          </li>
                          <li>
                            <p>{t("supporting_2")}</p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section m-0 p-0">
              <Container>
                <Row>
                  <Col>
                    <h2 className="mb-5">{t("kanban")}</h2>
                    <Table bordered variant="primary" className="text-center">
                      <thead>
                        <tr>
                          <th className="h5 font-weight-bold">{t("todo")}</th>
                          <th className="h5 font-weight-bold">{t("doing")}</th>
                          <th className="h5 font-weight-bold">{t("done")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="h5 font-weight-bold">
                            {contentfulStats.stats.todo}
                          </td>
                          <td className="h5 font-weight-bold">
                            {contentfulStats.stats.doing}
                          </td>
                          <td className="h5 font-weight-bold">
                            {contentfulStats.stats.done}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Layout>
      )}
    </I18n>
  )
}

export default withI18next()(Plan)

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
    contentfulStats(contentful_id: { eq: "2ocvrOVBwIVGlvlJSPxbjd" }) {
      title
      stats {
        todo
        done
        doing
      }
    }
  }
`
